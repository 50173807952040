<template>
  <page-content>

    <common-table
        ref="table"
        path="web/camera"
        :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{ record.nickName }}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <CameraEdit ref="cameraEdit" @success="getList"></CameraEdit>

  </page-content>
</template>
<script>
import CameraEdit from "./CameraEdit";

export default {
  components: {
    CameraEdit
  },
  data() {
    return {}
  },

  computed: {
    columns() {
      return [
        // {
        //     title: '用户头像',
        //     dataIndex: 'avatarUrl',
        //     scopedSlots: {customRender: 'logo'},
        //     width: 80
        // },
        // {
        //     title: '用户',
        //     dataIndex: 'nickName',
        // },
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '直播地址',
          dataIndex: 'link',
          ellipsis: true,
        },
        {
          title: '添加时间',
          dataIndex: 'createdAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    add() {
      this.$refs.cameraEdit.show()
    },
    view(record) {
      this.$router.push('/camera/' + record.id)
    },
    edit(record) {
      this.$refs.cameraEdit.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该摄像头?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/camera/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
